<template>
  <div
    class="mb-0"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-8' : 'px-4'"
  >
    <!-- ==== DATA Penjualan BULANAN ==== -->
    <div class="my-5 ">
      <div class="title text--secondary mb-3">
        Data Penjualan Bulanan
      </div>
      <div class="mb-3 d-flex align-center flex-wrap">

        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`franchise/report/bulanan/export_excel?store_id=${selected_store_id}`"
          :title="'Laporan Penjualan Bulanan'"
          ref="base-export-excel"
          v-on:onClickExport="responseExport"
        />
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'pdf'"
          :btnText="'Export PDF'"
          :btnIcon="'mdi-file-pdf-box'"
          :btnColor="'#f40f02'"
          :url="`franchise/report/bulanan/export_pdf?store_id=${selected_store_id}`"
          :title="'Laporan Penjualan Bulanan'"
          ref="base-export-pdf"
          v-on:onClickExport="responseExport"
        />

        <v-btn
          depressed
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#aaa9ad"
          class="white--text text-capitalize"
          @click="$refs['base-table'].isFilterVisible = true"
        >
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
        </v-btn>
      </div>

      <!-- ==== FILTER STATUS ==== -->
      <div v-if="status.bulan || status.store || status.cashier">
        <!-- status.bulan = false;
            dateMonth = '';
            date = '';
            filterSave.bulan = ''; -->
        Pencarian :
        <v-chip
          v-if="status.bulan"
          class="ma-2"
          close
          @click:close="handleCloseChips('month')"
        >
          Bulan: {{ dateMonth | moment }}
        </v-chip>
        <!-- <v-chip
          v-if="status.store"
          class="ma-2"
          close
          @click:close="
            status.store = false;
            filterstore = '';
            filterSave.store = '';
            initialize();
          "
        >
          Toko: {{ filternamestore }}
        </v-chip>
        <v-chip
          v-if="status.cashier"
          class="ma-2"
          close
          @click:close="
            status.cashier = false;
            filtercashier = '';
            filterSave.cashier = '';
            initialize();
          "
        >
          Pegawai: {{ filternamecashier }}
        </v-chip> -->
      </div>

      <!-- ==== DATA SUMMARY ==== -->
      <v-expansion-panels flat accordion v-model="panel" focusable>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header
            class="title text--secondary"
            style="font-size:1rem !important;"
            >Rincian Penjualan Bulanan</v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="pt-5 mb-2"
            style=" box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:5px;"
          >
            <v-row>
              <v-col cols="12" sm="3" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''"
                v-for="(item, key) in total" :key="key">
                <v-card class="box-shadow border-radius pa-2">
                  <v-card-actions class="pt-2 px-0">
                    <div style="width:100%">
                      <v-card-title
                        class="font-weight-bold text--secondary px-0 py-0 pb-1"
                        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: 11px; line-height: 1; height: 30px' : ''"
                        :class="$vuetify.breakpoint.name === 'xs' ? '' : 'body-2'"
                      >
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#d31145"
                              v-if="item.note.title != ''"
                              v-on="on"
                              v-bind="attrs"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div class="d-flex flex-column">
                            <span class="font-weight-bold"><b>{{item.note.title}}</b></span>
                            <span>{{ item.note.content }}</span>
                          </div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text :class="$vuetify.breakpoint.name === 'xs' ? 'title text-second px-0' : 'headline text-second px-0'">
                        {{ item.value }}
                      </v-card-text>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ==== LIST DATA PENJUALAN PER  BULAN ==== -->
      <div v-show="process.run && list.length < 1">
        <v-skeleton-loader
          :loading="process.run"
          transition="scale-transition"
          :tile="false"
          type="table-tbody"
        >
        </v-skeleton-loader>
      </div>

      <base-table
        v-on:onResetFilter="resetFilter"
        v-on:onRetrieveDataStart="responseDataStart"
        v-on:onRetrieveDataEnd="responseDataEnd"
        v-show="displayBaseTable"
        :isExternalFilterButton="true"
        :isFilterOnHeader="true"
        ref="base-table"
        dateType="date"
        dateFormat="DD MMMM YYYY"
        :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 450px)'"
        :filterHeader="filterHeader_salesMonthly"
        :single-select="singleSelect"
        :headers="headers"
        :retrieveDataURL="`franchise/report/bulanan?store_id=${selected_store_id}`"
        filterType="laporan"
      >
      
        <template v-slot:[`item.label_raw`]="{ item }">
          {{ item.label_raw | dateFull }}
        </template>

        <template v-slot:[`item.product_sold_qty_sum`]="{ item }">
          {{ item.product_sold_qty_sum | num_format }}
        </template>

        <template v-slot:[`item.product_price_sum`]="{ item }">
          {{ item.product_price_sum }}
        </template>

        <template v-slot:[`item.product_purchase_price_sum`]="{ item }">
          {{ item.product_purchase_price_sum }}
        </template>

        <template v-slot:[`item.bruto_price_sum`]="{ item }">
          {{ item.bruto_price_sum }}
        </template>

        <template v-slot:[`item.disount_sum`]="{ item }">
          {{ item.disount_sum }}
        </template>

        <template v-slot:[`item.charge_sum`]="{ item }">
          {{ item.charge_sum }}
        </template>

        <template v-slot:[`item.tax_sum`]="{ item }">
          {{ item.tax_sum }}
        </template>

        <template v-slot:[`item.other_cost_sum`]="{ item }">
          {{ item.other_cost_sum }}
        </template>

        <template v-slot:[`item.nett_price_sum`]="{ item }">
          {{ item.nett_price_sum }}
        </template>

        <template v-slot:filterTitle>
          Filter Ringkasan Penjualan Bulanan
        </template>

        <template v-slot:customFilter>

          <v-menu
            v-model="modalFilter"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                placeholder="Pilih Bulan"
                color="#d31145"
                class="mt-5"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              color="#d31145"
              @input="modalFilter = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </base-table>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-show="emptyData" />
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy, exportExcel } from "@/service/Axios";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import moment from "moment";
import Export from "@/components/Export";
export default {
  data() {
    return {
      panel: "",
      modalFilter: false,
      /**
       * DATA COMPONENT BASETABLE
       */

      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },

      headers: [
        { text: "Tanggal", align: "center", width: 150, value: "label_raw" },
        { text: "Produk Terjual", align: "end", width: 135, value: "product_sold_qty_sum" },
        {
          text: "Total Harga Jual (Rp)",
          align: "end",
          width: 180,
          value: "product_price_sum",
        },
        {
          text: "Total Harga Beli (Rp)",
          align: "end",
          width: 180,
          value: "product_purchase_price_sum",
        },
        {
          text: "Total Bruto (Rp)",
          align: "end",
          width: 180,
          value: "bruto_price_sum",
        },
        {
          text: "Total Diskon (Rp)",
          align: "end",
          width: 180,
          value: "disount_sum",
        },
        {
          text: "Total Servis (Rp)",
          align: "end",
          width: 180,
          value: "charge_sum",
        },
        {
          text: "Total PPN (Rp)",
          align: "end",
          width: 180,
          value: "tax_sum",
        },
        {
          text: "Total Biaya Tambahan (Rp)",
          align: "end",
          width: 200,
          value: "other_cost_sum",
        },
        {
          text: "Total Netto (Rp)",
          align: "end",
          width: 180,
          value: "nett_price_sum",
        },
      ],

      /**
       * END DATA COMPONENT BASETABLE
       */

      date: new Date().toISOString().substr(0, 7),
      dateMonth: "",
      filterstore: "",
      filternamestore: "",
      filtercashier: "",
      filternamecashier: "",
      filterdate: {},
      options: {},
      loading: false,
      totalData: 8,
      menu: false,

      process: {
        run: false,
        loading: false,
      },
      dialog: {
        filtered: false,
      },
      filterSave: {
        bulan: "",
        store: "",
      },
      status: {
        bulan: false,
        store: false,
      },
      store: [],
      cashier: [],
      list: [],
      total: {},
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      totalData: 0,
      selected: {
        pagination: 0,
      },
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  computed: {
    filterHeader_salesMonthly() {
      return [
        {
          type: "custom",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
      ];
    },

    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
  },
  filters: {
    moment: function(dateMonth) {
      return moment(dateMonth).format("MMMM YYYY");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    // 'selected.pagination': function(val) {
    //   this.initialize(val+1)
    // },
    // options:{
    //   handler(){
    //     this.initialize()
    //   },
    //   deep:true
    // }
  },
  created() {
    // this.initialize()
    this.fetchStore();
  },
  methods: {
    /**
     * EVENTS
     */
    resetFilter() {
      this.$refs["base-table"].params.month &&
        delete this.$refs["base-table"].params.month;

      this.status.bulan = false;
      this.date = "";
      this.dateMonth = "";
      
      this.$refs["base-table"].retrieveData();
    },

    handleCloseChips(mode) {
      switch (mode) {
        case "month":
          this.status.bulan = false;
          this.date = "";
          this.dateMonth = "";
          this.resetFilter();
          this.$refs["base-table"].retrieveData();
          break;
      }
    },

    responseDataStart() {
      this.loading = true;
      this.process.run = true;
      this.displayBaseTable = false;
      this.emptyData = false;
      /**
       * contains handler for filter params
       * date
       */

      //DATE
      if (this.date) {
        this.$refs["base-table"].params.date = this.date;

        //show chips
        this.status.bulan = true;
        this.dateMonth = this.date;
      }
    },

    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      this.fetchSummaryData(this.selected_store_id)
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    async fetchSummaryData (store_id) {
      const response  = await get(`franchise/report/bulanan/summary?`, {
        params: {
          store_id,
          date: this.date
        }
      })

      let res = response.data

      if (res.status == 200) {
        this.total = res.results.data
      }
    },

    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;

      // //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },
    /**
     * END EVENTS
     */

    disabledAfterThisMonth(date) {
      const today = new Date().getMonth();

      return date.getMonth() > today;
    },
    async Export() {
      this.process.formExport = true;
      this.process.formExport = false;
    },
  },
};
</script>
<style scoped>
.background-bank-cols {
  background-color: #cfd8dc;
  border: 3px solid #cfd8dc;
  border-radius: 10px;
}
</style>
